import { BrowserRouter, Routes, Route } from "react-router-dom";
import PracticePage from "./pages/PracticePage";
import Subscribe from "./pages/SubscriptionPage";
import AppNavbar from "./comp/Navbar";
import { Container } from "react-bootstrap";
import { auth } from "./config/firebase";
import { useState } from "react";
import { User } from "firebase/auth";
import { render } from "@testing-library/react";
import AppFooter from "./comp/Footer";
import Home from "./pages/Home";
import EssayPage from "./comp/Essay";
import ListEssayPage from "./comp/EssayList";
import Pricing from "./comp/Pricing";
import SampleReport from "./pages/SampleReportPage";

function App() {
  // TODO Move to this https://stackoverflow.com/questions/69675357/what-is-the-proper-way-to-do-global-state
  const [user, setUser] = useState<User>();
  const [renderAll, setRenderAll] = useState(false);
  auth.onAuthStateChanged((newUser) => {
    setUser(newUser);
    setRenderAll(true);
  });

  return (
    renderAll && (
      <BrowserRouter>
        <AppNavbar user={user} />
        <Container className="pt-4">
          <Routes>
            {/* TODO Split different flows into pages */}
            {/* TODO Route should always send to *Page.tsx. Components should be inside. */}
            <Route path="/subscribe" element={<Subscribe user={user} />} />
            <Route
              path="/practice"
              element={<PracticePage user={user} />}
            />
            <Route
              path="/practice/custom"
              element={<PracticePage user={user} customTopic={true} />}
            />
            <Route path="/" element={<Home user={user} setUser={setUser} />} />
            <Route path="essay">
              <Route path=":essayId" element={<EssayPage user={user} />} />
              <Route path="" element={<ListEssayPage user={user} />} />
            </Route>
            <Route
              path="assessments/:userId/:essayId"
              element={<EssayPage user={user}></EssayPage>}
            />
            <Route path="/pricing" element={<Pricing user={user} />} />
            <Route path="/sample" element={<SampleReport user={user} />} />
          </Routes>
        </Container>
        <AppFooter></AppFooter>
      </BrowserRouter>
    )
  );
}

export default App;
