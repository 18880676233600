import { useEffect, useState } from "react";
import { analytics, db } from "../config/firebase";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { Button, Stack } from "react-bootstrap";

export default function Pricing({ user }) {
  const [processing, setProcessing] = useState(false);
  // const defaultPriceId = "price_1OIlr9SDQlAFGXACpfUkyfVW"; //Test
  const defaultPriceId = "price_1OVDVMSDQlAFGXACbli0m5yJ";
  const [referralData, setReferralData] = useState({});
  const [referCodeRaw, setReferCodeRaw] = useState("");
  const redirectToPayment = async (e) => {
    setProcessing(true);
    logEvent(analytics, "buy_clicked", { uid: user.uid });
    const userObj = doc(collection(db, "users"), user.uid);
    const ckSess = collection(userObj, "checkout_sessions");
    const docRef = await addDoc(ckSess, {
      // price: "price_1OIkeqSDQlAFGXACfuFlwwe3", // PROD
      price: referralData["stripePriceId"] || defaultPriceId,
      success_url: window.location.origin + "/subscribe",
      cancel_url: window.location.origin + "/pricing",
      metadata: {
        referral: referralData["referCode"] || null
      }
    });

    const _cancelSnapshot = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
        logEvent(analytics, "buy_redirected_to_stripe", { uid: user.uid });
      }
    });
  };

  const emitEventPricing = () => {
    logEvent(analytics, "pricing_page_opened", { uid: user.uid });
    console.log("event sent");
  };

  useEffect(() => {
    emitEventPricing();
  }, []);

  const applyOffer = async (e) => {
    e.target.disabled = true;
    const referCodeCleaned = referCodeRaw.trim().toUpperCase();

    const referObj = doc(collection(db, "referrals"), referCodeCleaned);
    const obj = await getDoc(referObj);
    const referData = obj.data();

    if (referData == undefined) {
      alert("Invalid refer code");
    } else {
      referData["referCode"] = referCodeCleaned;
      setReferralData(referData);
    }

    e.target.disabled = false;
  };

  return (
    <>
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h1 className="display-4 fw-normal">Pricing</h1>
        <p className="fs-5 text-muted">
          Supercharge your IELTS preparation with PinPen Premium – the key to
          unlimited assessments, expert feedback, and exam success tailored to
          you
        </p>
      </div>

      <div className="row row-cols-1 row-cols-md-3 mb-3 text-center justify-content-md-center">
        <div className="col">
          <div className="card mb-4 rounded-3 shadow-sm">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">Premium</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                {referralData["offerPrice"] ? (
                  <>
                    <del className="small">₹499</del>
                    {" ₹"}
                    {referralData["offerPrice"]}
                  </>
                ) : (
                  "₹499"
                )}
                <small className="text-muted fw-light">/mo</small>
              </h1>
              <ul className="mt-3 mb-4 text-start ps-6">
                <li>Unlimited Assessments</li>
                <li>Detailed Feedback</li>
                <li>Focus Criteria</li>
                <li>Progress Tracking</li>
                <li>Priority Support</li>
                <li>Exclusive Updates</li>
              </ul>
              <Stack className="mb-2" direction="horizontal" gap={2}>
                <input
                  type="text"
                  className="form-control text-center border border-primary border-2 me-auto"
                  placeholder="Referral Code"
                  id="refer"
                  onChange={(e) => setReferCodeRaw(e.target.value)}
                />
                <Button variant="secondary" onClick={applyOffer}>
                  Apply
                </Button>
              </Stack>
              <button
                type="button"
                className="w-100 btn btn-lg btn-primary"
                onClick={redirectToPayment}
                disabled={processing}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
