import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { Badge, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FirebaseError } from "firebase/app";
import { Link, useNavigate } from "react-router-dom";
import Timer from "./Timer";
import GetMetricsForEssay from "./utils";

const loadData = async () => {
  console.log("loading data...");
  const topicsRef = collection(db, "topics");

  const docsRef = await getDocs(topicsRef);
  const ret = [];
  docsRef.forEach((doc) => {
    const record = {
      id: doc.id,
      ...doc.data(),
    };
    ret.push(record);
  });
  return ret;
};
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function getRandomTopic(topics) {
  return topics[getRandomInt(topics.length)].topic;
}

export default function PracticePanel({ user, customTopic = false }) {
  const navigate = useNavigate();

  const [topic, setTopic] = useState<any>({});
  const [essay, setEssay] = useState("");
  const [essayMetrics, setEssayMetrics] = useState({
    wordCount: 0,
    paraCount: 0,
  });
  const [dataLoaded, setDataLoaded] = useState(false);

  const [evaluating, setEvaluating] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const setDataInComponent = async () => {
    const res = await loadData();
    const index = getRandomInt(res.length);
    if (customTopic) {
      const customTopicObj = {
        id: null,
        topic: prompt("Topic:"),
      };
      setTopic(customTopicObj);
    } else {
      setTopic(res[index]);
    }
    setDataLoaded(true);
    // console.log(res);
  };

  const updateEssay = (essay) => {
    setEssay(essay);
    setEssayMetrics(GetMetricsForEssay(essay));
  };

  useEffect(() => {
    if (!dataLoaded) setDataInComponent();
  }, []);

  const evaluateEssay = async () => {
    if (essayMetrics.wordCount < 50) {
      alert("Please write atleast 50 words.");
      return;
    }
    setEvaluating(true);
    const users = collection(db, "users");
    const currentUserDoc = doc(users, user.uid);
    const assessments = collection(currentUserDoc, "assessments");

    let docRef;
    try {
      docRef = await addDoc(assessments, {
        topic: topic.topic,
        topicId: topic.id,
        essay: essay,
      });
    } catch (err) {
      const permError = err.message == "Missing or insufficient permissions.";
      if (err instanceof FirebaseError && permError) {
        // alert("Please subscribe to access this feature.");
        handleModalShow();
      } else {
        console.error(err);
      }
      setEvaluating(false);
      return;
    }

    const cancelSnapshot = onSnapshot(docRef, (snap) => {
      const { response_text } = snap.data();
      console.log("Received snapshot");
      if (response_text) {
        const data = snap.data();
        setEvaluating(false);
        cancelSnapshot();

        // Optimise this from cache
        if (customTopic) {
          navigate("/assessments/" + user.uid + "/" + docRef.id, {
            state: { essayData: data },
          });
        } else {
          navigate("/essay/" + docRef.id, { state: { essayData: data } });
        }
      }
    });
  };

  return dataLoaded ? (
    <>
      <Modal show={modalShow} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please <Link to="/subscribe">subscribe</Link> to access this feature.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col xs={10}>
          <Badge bg="primary">250 words</Badge>
          {"  "}
          <Badge bg="primary">40 minutes</Badge>
          <br />
          Topic for writing: <b>{topic.topic}</b>
        </Col>
        <Col xs={2} className="my-auto lead text-center">
          [<Timer seconds={40 * 60} onTimesUp={evaluateEssay} />]
        </Col>
      </Row>

      <Form.Control
        className="mt-2"
        rows={10}
        as="textarea"
        onChange={(e) => updateEssay(e.target.value)}
        disabled={evaluating}
        spellCheck={false}
      />
      <Row>
        <i className="col-6 small">Word Count: {essayMetrics.wordCount}</i>
        <i className="col-6 small text-end">
          Paragraphs: {essayMetrics.paraCount}
        </i>
      </Row>
      <Row className="mt-2">
        <Col>
          <Button
            variant="success"
            onClick={evaluateEssay}
            disabled={evaluating}
          >
            {evaluating ? (
              <>
                <Spinner animation="grow" role="status" size="sm" />
                &nbsp;Checking
              </>
            ) : (
              "Check"
            )}
          </Button>{" "}
          {evaluating && (
            <span className="small">This may take upto a minute</span>
          )}
        </Col>
      </Row>
      <div className="mt-4"></div>
    </>
  ) : (
    <>Loading data ...</>
  );
}
