import { Link } from "react-router-dom";

export default function AppFooter() {
  return (
    <footer className="border-top mt-2">
    <div className="container d-flex flex-wrap justify-content-between align-items-center py-1 my-1 ">
    <p className="col-md-4 mb-0 text-muted">© 2024 PinPen</p>

    <div  className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
      <img className="d-inline-block" style={{filter: "invert(100%)"}} loading="lazy" src="/img/logo-no-background.png" height="25px" alt="hero image"/>
    </div>

    <ul className="nav col-md-4 justify-content-end">
      <li className="nav-item"><a href="https://pinpen.co/blog.php" className="nav-link px-2 text-muted">Blog</a></li>
      <li className="nav-item"><a href="https://pinpen.co/#about" className="nav-link px-2 text-muted">Features</a></li>
      <li className="nav-item"><Link to="/pricing" className="nav-link px-2 text-muted">Pricing</Link></li>
      {/* <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">FAQs</a></li> */}
      {/* <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li> */}
    </ul>

    </div>
  </footer>
  );
}
