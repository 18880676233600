import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";

function Subscribe({ user }) {
  const [subData, setSubData] = useState<any>();
  const navigate = useNavigate();
  const loadSubscriptionData = async () => {
    const users = collection(db, "users");
    const userDoc = doc(users, user.uid);
    const userSubscriptions = collection(userDoc, "subscriptions");
    const docsRef = await getDocs(userSubscriptions);

    // Getting all docs
    const ret = [];
    docsRef.forEach((doc) => {
      const record = {
        id: doc.id,
        ...doc.data(),
      };
      ret.push(record);
    });

    const subObj = ret[0];
    setSubData(subObj);
    if (!subObj){
      navigate('/pricing');
    }
  };
  useEffect(() => {
    loadSubscriptionData();
  }, []);

  if (!user) {
    return <>User not logged in</>;
  }
  return (
    <Container>
      <h1>Subscription Details</h1>
      Name: {user.displayName}
      <br />
      Email: {user.email} <br />
      {subData ? (
        <>
          {" "}
          Subscription Start:{" "}
          {subData.current_period_start.toDate().toISOString().split("T")[0]}
          <br />
          Subscription End:{" "}
          {subData.current_period_end.toDate().toISOString().split("T")[0]}
          <br />
        </>
      ) : (
        <>No Payment Data Available</>
      )}
    </Container>
  );
}

export default Subscribe;
