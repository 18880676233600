import { useLocation, useParams } from "react-router-dom";
import { db } from "../config/firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import Report from "./Report";

export default function EssayPage({
  user,
  essayId = undefined,
  userId = undefined,
}) {
  // reading report data from state
  const { state } = useLocation();
  const { essayData } = state || {}; // undefined if does not exists
  const [reportDataState, setReportDataState] = useState<any>(essayData);

  let { essayId: essayIdParams, userId: userIdParams } = useParams();

  const loadData = async () => {
    const essayIdValForLookup = essayId || essayIdParams;
    const userIdValForLookup = userId || userIdParams || user.uid;
    const users = collection(db, "users");
    const currentAssessmentRef = doc(
      users,
      userIdValForLookup + "/assessments/" + essayIdValForLookup
    );
    const currentAssessment = await getDoc(currentAssessmentRef);
    setReportDataState(currentAssessment.data());
  };

  useEffect(() => {
    if (!reportDataState) loadData();
  }, []);

  console.log(reportDataState);

  return reportDataState ? (
    <Report reportData={reportDataState} />
  ) : (
    <>Loading Details...</>
  );
}
