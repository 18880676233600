import PracticePanel from "../comp/Practice";
import { Navigate } from "react-router-dom";

function PracticePage({ user, customTopic = false }) {
  return user ? (
    <PracticePanel user={user} customTopic={customTopic}></PracticePanel>
  ) : (
    <Navigate to="/" />
  );
}

export default PracticePage;
