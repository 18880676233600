import { useEffect, useRef, useState } from "react";

function minTwoDigits(n) {
  return (n < 10 ? "0" : "") + n;
}

export default function Timer({ seconds, onTimesUp = () => {} }) {
  const [secondsRem, setSecondsRem] = useState(seconds);

  const deadline = useRef(-1);
  const timerFinished = useRef(false);

  if (deadline.current < 0) {
    deadline.current = Date.now() + seconds * 1000;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const rem = Math.round((deadline.current - Date.now()) / 1000);
      if (rem <= 0) {
        clearInterval(intervalId);
        if (!timerFinished.current){
            onTimesUp();
            timerFinished.current = true;
        }
      }
      if (rem>=0)
        setSecondsRem(rem);
    }, 1000);

    const clearFn = () => {
      clearInterval(intervalId);
    };
    return clearFn;
  }, []);

  return (
    <>
      <span>
        {minTwoDigits(Math.floor(secondsRem / 60))}:
        {minTwoDigits(secondsRem % 60)}
      </span>
    </>
  );
}
