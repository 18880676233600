function GetMetricsForEssay(essay: string) {
    const removeBlanksFilter = (word) => word !== "";
    const paras = essay.split(/\n/).filter(removeBlanksFilter);
    const words = paras.map((paras) =>
      paras.split(/\s/).filter(removeBlanksFilter)
    );
    const wordCount = words.reduce((cumSum, curr) => cumSum + curr.length, 0);
    const paraCount = paras.length;
    return { wordCount, paraCount };
  }

  
  export default GetMetricsForEssay;