import {
  faCircleCheck,
  faListCheck,
  faSpellCheck,
  faTape,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { report } from "process";
import { useRef, useState } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";
import { OverlayDelay } from "react-bootstrap/esm/OverlayTrigger";
import GetMetricsForEssay from "./utils";

function isYes(s: string) {
  if (s == "Yes") {
    return true;
  } else if (s == "No") {
    return false;
  }
  return undefined;
}

function isNo(s: string) {
  if (s == "No") {
    return true;
  } else if (s == "Yes") {
    return false;
  }
  return undefined;
}

const iconSuccess = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    width="14"
    viewBox="0 0 448 512"
  >
    <path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z" />
  </svg>
);

const iconFailed = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    width="12"
    viewBox="0 0 384 512"
  >
    <path
      fill="#FF0000"
      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
    />
  </svg>
);

function GetIcon(value) {
  if (value == undefined) {
    return <>~</>;
  } else if (value) {
    return iconSuccess;
  }
  return iconFailed;
}

function GetStructuredListElement({ listData }) {
  return (
    <li className={listData.value == false ? "text-danger" : ""}>
      {GetIcon(listData.value)} {listData.desc}
    </li>
  );
}

function parseStructuredResponse(response: string) {
  const lines = response.split("\n").filter((s) => s.trim() != "");
  return {
    taQ1: { desc: "fully explains the topic", value: isYes(lines[3]) },
    taQ2: { desc: "relevant and specific examples", value: isYes(lines[6]) },
    taQ3: { desc: "presents all relevant ideas", value: isYes(lines[9]) },
    taQ4: { desc: "answers all parts of topic", value: isYes(lines[12]) },
    cacQ1: {
      desc: "contains introduction and conclusion",
      value: isYes(lines[16]),
    },
    cacQ2: {
      desc: "contains one main idea per paragraph",
      value: isYes(lines[19]),
    },
    cacQ3: {
      desc: "contains cohesive linking words accurately",
      value: isYes(lines[22]),
    },
    cacQ4: {
      desc: "contains structured logical paragraphs",
      value: isYes(lines[25]),
    },
    cacQ5: {
      desc: "contains varied linking phrases using synonyms",
      value: isYes(lines[28]),
    },
    lrQ1: {
      desc: "contains varied vocabulary with accurate synonyms",
      value: isYes(lines[32]),
    },
    lrQ2: {
      desc: "uses less common topic specific words",
      value: isYes(lines[35]),
    },
    lrQ3: {
      desc: "contains spelling and word formation mistakes",
      value: isNo(lines[38]),
    },
    gracQ1: {
      desc: "contains mix of complex and simple sentences",
      value: isYes(lines[42]),
    },
    gracQ2: {
      desc: "contains writing grammatical errors",
      value: isNo(lines[45]),
    },
  };
}

function TextWithGrammarFeedback({ original, fix, id }) {
  const [showFix, setShowFix] = useState(false);
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      show={show} // Control trigger behavior with show instead of trigger.
      overlay={
        <Popover
          id={"text-popover-" + id.toString()}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <Popover.Header as="h3">Grammar Check</Popover.Header>
          <Popover.Body>
            <strong>"{original}"</strong> can be written as{" "}
            <strong>"{fix}"</strong>
          </Popover.Body>
        </Popover>
      }
    >
      <span
        className={showFix ? "" : "text-danger text-highlight-hover"}
        style={showFix ? {} : { cursor: "pointer" }}
        onClick={() => setShowFix(true)}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        ref={ref}
      >
        {showFix ? fix : original}
      </span>
    </OverlayTrigger>
  );
}

function getEssayWithGrammarHighlights(reportData) {
  const response_text_grammar = reportData.response_text_grammar;
  if (!response_text_grammar) return <>{reportData.essay}</>;
  const grammarResponse = reportData.response_text_grammar.split("\n");
  const grammarText = grammarResponse.slice(2, -1).join("\n");
  const regex = /\((?<original>.+?)\)\((?<fix>.+?)\)/g;
  const regex_matches = Array.from(grammarText.matchAll(regex));

  const elements = [];
  let prevIndex = 0;
  regex_matches.forEach((match) => {
    const matchLength = match[0].length;
    const matchStart = match["index"];
    const original = match[1];
    const fix = match[2];

    const previousContent = grammarText.slice(prevIndex, matchStart);
    prevIndex = matchStart + matchLength;
    elements.push(
      <span key={"pre-text-" + matchStart.toString()}>{previousContent}</span>
    );
    elements.push(
      <TextWithGrammarFeedback
        key={matchStart}
        original={original}
        fix={fix}
        id={"start-" + matchStart.toString()}
      />
    );
  });
  elements.push(<span key="pre-text-end">{grammarText.slice(prevIndex)}</span>);
  return elements;
}

export default function Report({ reportData }) {
  const total =
    reportData.task_achievement_score +
    reportData.coherence_and_cohesion_score +
    reportData.lexical_resource_score +
    reportData.grammatical_range_and_accuracy_score;

  const avg = Math.round(total / 2) / 2;

  const un = reportData.response_text_structured;
  const parsedResponse = parseStructuredResponse(un);
  const essayMetrics = GetMetricsForEssay(reportData.essay);

  return (
    <>
      <Row>
        <div className="mb-2">
          Topic: <b>{reportData.topic}</b>
        </div>
        <div className="mb-1"> Essay: </div>
      </Row>

      <Row className="mb-4" fluid="sm">
        <Col xs={12} md={8} lg={9}>
          <div
            className="mb-4 border p-2 rounded"
            style={{ whiteSpace: "pre-wrap", height: "100%" }}
          >
            {getEssayWithGrammarHighlights(reportData)}
          </div>
        </Col>
        {/* Tip: Each class scales up, so if you wish to set the same widths for xs and sm, you only need to specify xs.  */}
        <Col className="mt-2 mt-md-0" xs={12} md={4} lg={3}>
          <Card className="text-center bg-success bg-gradient">
            <Card.Body>
              <Card.Title>Score Board</Card.Title>
              {/* <Card.Subtitle className="mb-2 text-muted">
                Card Subtitle
              </Card.Subtitle> */}
              <Card.Text as="div">
                {/* Some quick example text to build on the card title and make up
                the bulk of the card's content.
                 */}
                <div>
                  <span className="fs-1 text-success fw-bold">{avg}</span>
                  <br />
                  <span> Overall Band Score</span>
                  <br />
                </div>
                <hr />
                <Row className="mt-2">
                  <Col>
                    <span className="text-success">
                      {essayMetrics.paraCount}
                    </span>{" "}
                    paragraph(s)
                  </Col>
                  {/* <div className="vr" /> */}
                  <Col>
                    <span className="text-success">
                      {essayMetrics.wordCount}
                    </span>{" "}
                    words
                  </Col>
                </Row>
              </Card.Text>
              {/* <Card.Link href="#">Card Link</Card.Link>
              <Card.Link href="#">Another Link</Card.Link> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col className="col-12 col-md-8">
          <h3>
            <FontAwesomeIcon icon={faListCheck} /> Task Achievement:{" "}
            {reportData.task_achievement_score}
          </h3>
          <div className="lead">{reportData.task_achievement_review}</div>
        </Col>
        <Col className="col-12 col-md-4 my-2 my-md-auto">
          <ul className="list-unstyled mb-0">
            <GetStructuredListElement listData={parsedResponse.taQ1} />
            <GetStructuredListElement listData={parsedResponse.taQ2} />
            <GetStructuredListElement listData={parsedResponse.taQ3} />
            <GetStructuredListElement listData={parsedResponse.taQ4} />
          </ul>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col className="col-12 col-md-8">
          <h3>
            <FontAwesomeIcon icon={faTape} /> Coherence and Cohesion:{" "}
            {reportData.coherence_and_cohesion_score}
          </h3>
          <div className="lead">{reportData.coherence_and_cohesion_review}</div>
        </Col>
        <Col className="col-12 col-md-4 my-2 my-md-auto">
          <ul className="list-unstyled mb-0">
            <GetStructuredListElement listData={parsedResponse.cacQ1} />
            <GetStructuredListElement listData={parsedResponse.cacQ2} />
            <GetStructuredListElement listData={parsedResponse.cacQ3} />
            <GetStructuredListElement listData={parsedResponse.cacQ4} />
            <GetStructuredListElement listData={parsedResponse.cacQ5} />
          </ul>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col className="col-12 col-md-8">
          <h3>
            <FontAwesomeIcon icon={faCircleCheck} /> Grammatical Range and
            Accuracy: {reportData.grammatical_range_and_accuracy_score}
          </h3>
          <div className="lead">
            {reportData.grammatical_range_and_accuracy_review}
          </div>
        </Col>
        <Col className="col-12 col-md-4 my-2 my-md-auto">
          <ul className="list-unstyled mb-0">
            <GetStructuredListElement listData={parsedResponse.gracQ1} />
            <GetStructuredListElement listData={parsedResponse.gracQ2} />
          </ul>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col className="col-12 col-md-8">
          <h3>
            <FontAwesomeIcon icon={faSpellCheck} /> Lexical Resource:{" "}
            {reportData.lexical_resource_score}
          </h3>
          <div className="lead">{reportData.lexical_resource_review}</div>
        </Col>
        <Col className="col-12 col-md-4 my-2 my-md-auto">
          <ul className="list-unstyled mb-0">
            <GetStructuredListElement listData={parsedResponse.lrQ1} />
            <GetStructuredListElement listData={parsedResponse.lrQ2} />
            <GetStructuredListElement listData={parsedResponse.lrQ3} />
          </ul>
        </Col>
      </Row>
      <br />
      <br />
    </>
  );
}
