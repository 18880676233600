import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleCheck, faHouse, faPenNib, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";

export default function AppNavbar({ user }) {
  const navigate = useNavigate();
  const signOutAuth = () => {
    signOut(auth);
    navigate("/");
  };
  return (
    <>
      <Navbar expand="sm">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              className="d-inline-block"
              style={{ filter: "invert(100%)" }}
              loading="lazy"
              src="/img/logo-no-background.png"
              height="25px"
              alt="hero image"
            />{" "}
            <sup>beta</sup>
          </Navbar.Brand>

          {/* <Nav className="me-auto">
            <Nav.Link as={Link} to="/subscribe">
              Subscribe
            </Nav.Link>
          </Nav> */}
          {
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  {user ? (
                    <>
                      <Nav.Link as={Link} to="/">
                      <FontAwesomeIcon icon={faHouse} /> Home
                      </Nav.Link>
                      <Nav.Link as={Link} to="/sample">
                      <FontAwesomeIcon icon={faFileCircleCheck} /> Sample
                      </Nav.Link>

                      <NavDropdown
                        title={<><FontAwesomeIcon icon={faUser} /> {user?.displayName ? user?.displayName: "User" }</>}
                        id="navbarScrollingDropdown"
                      >
                        <NavDropdown.Item onClick={signOutAuth}>
                        <FontAwesomeIcon icon={faRightFromBracket} /> Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </>
                  ) : (
                    <>
                      <Nav.Link as={Link} to="/">
                      <FontAwesomeIcon icon={faPenNib} /> Start Practice
                      </Nav.Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </>
          }
        </Container>
      </Navbar>
    </>
  );
}
