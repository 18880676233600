import { Button, Form } from "react-bootstrap";
import { auth, fbProvider, googleProvider } from "../config/firebase";
import { sendSignInLinkToEmail, signInWithPopup } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

// For login with email
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: window.location.origin,
  // This must be true.
  handleCodeInApp: true,
  // dynamicLinkDomain: 'app.pinpen.co'
};

export const LoginCard = ({ user, setUser }) => {
  const isLoggedIn = user != null;
  const [email, setEmail] = useState("");

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider).then((cred) => {
      setUser(cred.user);
    });
  };
  const signInWithFb = () => {
    signInWithPopup(auth, fbProvider).then((cred) => {
      setUser(cred.user);
    });
  };

  const signInWithEmail = () => {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        alert("Email sent. Please open the link sent in your email to login.");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("Error occurred while generating link.");
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  return (
    <div>
      {!isLoggedIn && (
        <>
          <section className="p-3 p-md-4 p-xl-5 text-center">
            <div className="container">
              <div className="card border-light-subtle shadow-sm">
                <div className="row g-0">
                  <div className="col-12 col-md-6">
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <img
                        className="img-fluid"
                        loading="lazy"
                        src="./img/hero-square-small.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="card-body p-3 pt-5 p-md-4 p-xl-5">
                      <div className="row"></div>
                      <h3 className="h3 ">Log in or Create an Account</h3>
                      <hr className="border-primary-subtle mb-4" />
                      <div className="mb-4">
                        Quickly get started by sign up or signing in using your
                        existing accounts. By proceeding, you are agreeing to
                        PinPen's Terms of Use and Privacy Policy
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-column">
                            <Form onSubmit={(e) => e.preventDefault()}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Enter email"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </Form.Group>
                            </Form>
                            <a
                              href="#"
                              className="btn bsb-btn-xl btn-outline-primary mb-2"
                              onClick={signInWithEmail}
                            >
                              <FontAwesomeIcon icon={faRightToBracket} />{" "}
                              Generate Login Link
                            </a>
                            <a
                              href="#"
                              className="btn bsb-btn-xl btn-outline-primary"
                              onClick={signInWithGoogle}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-google"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                              </svg>
                              <span className="ms-2 fs-6">
                                Login with Google
                              </span>
                            </a>
                            {/* <a
                              href="#!"
                              className="btn bsb-btn-xl btn-outline-primary"
                              onClick={signInWithFb}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-google"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                              </svg>
                              <span className="ms-2 fs-6">Facebook</span>
                            </a> */}
                          </div>
                        </div>
                      </div>
                      <div className="small"></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};
