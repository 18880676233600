import { Image, Row, Spinner } from "react-bootstrap";
import { LoginCard } from "../comp/Login";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "../config/firebase";

const CheckForLoginLink = ({ children, setUser }) => {
  const navigate = useNavigate();
  const isEmailLinkUrl = isSignInWithEmailLink(auth, window.location.href);
  const checkIfEmailLinkUrl = () => {
    // this is for handling email link
    if (isEmailLinkUrl) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
        if (!email) {
          navigate("/");
          return;
        }
      }
      // The client SDK will parse the code from the link for you.

      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          setUser(result.user);
          navigate("/");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.error(error);
          alert("Failed to login with link!");
        });
    }
  };

  useEffect(() => {
    console.log("test12");
    checkIfEmailLinkUrl();
  }, []);

  return isEmailLinkUrl ? (
    <>
      {" "}
      <Row className="justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Row>
    </>
  ) : (
    children
  );
};

export default function Home({ user, setUser }) {
  const navigate = useNavigate();
  const [questionType, setQuestionType] = useState("default");

  const redirectToPractice = () => {
    if (questionType != "default") {
      navigate("/practice");
    } else {
      alert("Please select question type");
    }
  };

  return user ? (
    <>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-3 text-center">
          Practice now:
          <div className="mt-2">
            <select
              className="form-control mr-sm-2"
              onChange={(e) => setQuestionType(e.target.value)}
              defaultValue="default"
            >
              <option value="default">Choose Question...</option>
              <option value="academic-task-2">Academic Task 2</option>
              <option value="general-task-2">General Task 2</option>
            </select>
            <button
              type="submit"
              className="btn btn-primary my-2"
              onClick={redirectToPractice}
            >
              Start!
            </button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <CheckForLoginLink setUser={setUser}>
      <LoginCard user={user} setUser={setUser} />
    </CheckForLoginLink>
  );
}
